<template>
  <v-container class="pa-0">
    <v-row>
      <v-col
        v-for="(data, key) of value"
        :key="key"
        cols="12"
        md="6"
      >
        <card-view
          class="scenario-card"
          :is-editable="isEditAllowed"
          :show-created-date="false"
          :is-deleteable="isDeleteAllowed"
          :delete-title="'Delete Scenario'"
          :delete-message="`Are you sure you want to delete ${data.name}?`"
          @edit="routeToEdit(data)"
          @delete="$emit('delete', data)"
        >
          <template v-slot:card-title>
            <div class="d-flex align-center">
              <common-input-checkbox
                v-model="data.is_checked"
                hide-details
                class="pt-0 mt-0"
                :readonly="!data.is_checked && checkedList.length === 2"
                @click="errorHandler(data)"
                @change="selectScenario(data)"
              />
              <div class="text-h4 font-weight-medium primary--text text-capitalize">
                {{ data.name }}
              </div>
            </div>
          </template>
          <template v-slot:custom-card-caption>
            <div class="d-flex ml-n1">
              <div class="d-flex align-center mr-5">
                <div class="trsText--text font-weight-medium text-body-2 mr-2">
                  Exit Value:
                </div>
                <div class="trsText--text text-body-1 font-weight-bold">
                  {{ $currencyWithPrecision(data.waterfall.exit_amount) }}
                </div>
              </div>
              <div class="d-flex align-center">
                <div class="trsText--text font-weight-medium text-body-2 mr-2">
                  Exit Date:
                </div>
                <div class="trsText--text text-body-1 font-weight-bold">
                  {{ $date(data.waterfall.exits_on) }}
                </div>
              </div>
            </div>
          </template>
          <template v-slot:sub-cards>
            <v-expansion-panels
              focusable
              multiple
            >
              <v-expansion-panel
                v-for="(roundData, index) in data.scenario_rounds"
                :key="index"
              >
                <v-expansion-panel-header>
                  <span class="trsText--text text-body-1">{{ roundData.name }}</span>
                  <span class="text-right mr-5">
                    <common-trs-btn
                      v-feature="CONSTS.CAPTABLE_FEATURES.EDIT_SCENARIO"
                      type="tertiary"
                      class="mx-1 rounded-0"
                      icon
                      color="primary"
                      @click="goToEditFinancingRound(data.id, roundData.name)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </common-trs-btn>
                    <common-app-confirm
                      v-feature="CONSTS.CAPTABLE_FEATURES.DELETE_SCENARIO"
                      yes-button="Yes"
                      no-button="Cancel"
                      :title="`Delete Financing Round`"
                      :message="`Are you sure you want to delete ${roundData.name}`"
                      @yes="deleteFinancingRound(data, index)"
                    >
                      <template v-slot:activator="{ on }">
                        <common-trs-btn
                          type="tertiary"
                          color="primary"
                          icon
                          text
                          v-on="on"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </common-trs-btn>
                      </template>
                    </common-app-confirm>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <!-- Defaut Summary Section -->
                  <v-row class="summary-section">
                    <v-col cols="6">
                      <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">Pre-money valuation:</span>
                      <span class="trsText--text text-body-1 font-weight-bold">{{ $currencyWithPrecision(roundData.pre_money_valuation) }}</span>
                    </v-col>
                    <v-col cols="6">
                      <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">Pre-issuance fully diluted shares:</span>
                      <span class="trsText--text text-body-1 font-weight-bold">{{ $number(roundData.pre_fully_diluted_shares) }}</span>
                    </v-col>
                    <v-col cols="6">
                      <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">Post-money valuation:</span>
                      <span class="trsText--text text-body-1 font-weight-bold">{{ $currencyWithPrecision(roundData.post_money_valuation) }}</span>
                    </v-col>
                    <v-col cols="6">
                      <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">Post-issuance fully diluted shares:</span>
                      <span class="trsText--text text-body-1 font-weight-bold">{{ $number(roundData.post_fully_diluted_shares) }}</span>
                    </v-col>
                    <v-col cols="6">
                      <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">Price per share:</span>
                      <span class="trsText--text text-body-1 font-weight-bold">{{ $currencyWithPrecision(roundData.price_per_share) }}</span>
                    </v-col>
                    <v-col cols="6">
                      <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">Dilution:</span>
                      <span class="trsText--text text-body-1 font-weight-bold">
                        {{ getPercentValue(roundData.dilution) }}
                      </span>
                    </v-col>
                    <v-col cols="6">
                      <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">Closing date:</span>
                      <span class="trsText--text text-body-1 font-weight-bold">{{ getDate(roundData.finally_closed_on) }}</span>
                    </v-col>
                  </v-row>
                  <!-- Liquidation Preferences Section -->
                  <v-divider
                    v-if="roundData.liquidation_preference && roundData.liquidation_preference.include_interest"
                    class="my-2"
                  />
                  <v-row
                    v-if="roundData.liquidation_preference && roundData.liquidation_preference.include_interest"
                    class="summary-section"
                  >
                    <v-col
                      cols="12"
                      class="trsText--text text-body-1 font-weight-bold text--lighten-1"
                    >
                      Liquidation Preferences
                    </v-col>
                    <v-col cols="6">
                      <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">
                        Interest rate:
                      </span>
                      <span class="trsText--text text-body-1 font-weight-bold">
                        {{ parseFloat(roundData.liquidation_preference.interest_terms.rate).toFixed(2) }} %
                      </span>
                    </v-col>
                    <v-col cols="6">
                      <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">
                        Multiple:
                      </span>
                      <span class="trsText--text text-body-1 font-weight-bold">
                        {{ roundData.liquidation_preference.multiple }}
                      </span>
                    </v-col>
                    <v-col cols="6">
                      <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">
                        Compound period:
                      </span>
                      <span class="trsText--text text-body-1 font-weight-bold">
                        {{ roundData.liquidation_preference.interest_terms.type }}
                      </span>
                    </v-col>
                    <v-col cols="6">
                      <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">
                        Participating cap:
                      </span>
                      <span class="trsText--text text-body-1 font-weight-bold">
                        {{ $currencyWithPrecision(roundData.liquidation_preference.participating_preference.participating_cap) }}
                      </span>
                    </v-col>
                    <v-col cols="6">
                      <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">
                        Accrual period:
                      </span>
                      <span class="trsText--text text-body-1 font-weight-bold">
                        {{ roundData.liquidation_preference.interest_terms.period }}
                      </span>
                    </v-col>
                    <v-col cols="6">
                      <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">
                        Previous class:
                      </span>
                      <span class="trsText--text text-body-1 font-weight-bold">
                        {{ roundData.liquidation_preference.previous_class }}
                      </span>
                    </v-col>
                  </v-row>
                  <!-- Equity incentive plan Section -->
                  <v-divider class="my-2" />
                  <v-row class="summary-section">
                    <v-col cols="6">
                      <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">
                        Equity incentive plan shuffle:
                      </span>
                      <span class="trsText--text text-body-1 font-weight-bold">
                        {{ getPercentValue(roundData.equity_incentive_plan_shuffle) }}
                      </span>
                    </v-col>
                    <v-col cols="6">
                      <span class="trsText--text font-weight-light text-body-1 text--lighten-1 mr-3">
                        Convert convertibles:
                      </span>
                      <span class="trsText--text text-body-1 font-weight-bold">
                        {{ roundData.convert_convertibles ? 'Yes' : 'No' }}
                      </span>
                    </v-col>
                  </v-row>
                  <!-- Investor List Section -->
                  <v-divider class="my-2" />
                  <v-row>
                    <v-col>
                      <common-trs-data-table
                        :headers="investorsTableHeader"
                        :items="roundData.investors"
                        :hide-default-footer="roundData.investors.length <= 5"
                      >
                        <template v-slot:[`item.invested_capital`]="{ item }">
                          <span class="trs--text text-body-1">
                            {{ $currencyWithPrecision(item.invested_capital) }}
                          </span>
                        </template>
                        <template v-slot:[`item.granted_shares`]="{ item }">
                          <span class="trs--text text-body-1">
                            {{ $number(item.granted_shares) }}
                          </span>
                        </template>
                        <template v-slot:[`item.ratio_fully_diluted`]="{ item }">
                          <span class="trs--text text-body-1">
                            {{ getPercentValue(item.ratio_fully_diluted) }}
                          </span>
                        </template>
                        <template v-slot:[`body.append`]="{}">
                          <tr class="px-7 footer-bg">
                            <td>
                              Total
                            </td>
                            <td>
                              {{ $currencyWithPrecision(calculateSum(roundData, 'invested_capital')) }}
                            </td>
                            <td>
                              {{ $number(calculateSum(roundData, 'granted_shares')) }}
                            </td>
                            <td>
                              {{ getPercentValue(calculateSum(roundData, 'ratio_fully_diluted')) }}
                            </td>
                          </tr>
                        </template>
                      </common-trs-data-table>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-row class="mt-2">
              <v-col v-feature="CONSTS.CAPTABLE_FEATURES.CREATE_SCENARIO_FINANCING_ROUND">
                <div
                  class="d-flex primary--text text-body-1 pt-1 font-weight-light cursor-pointer font-weight-medium"
                  @click="goToFinancing(data.id)"
                >
                  <v-icon class="mr-2 primary--text theme--light">
                    mdi-plus
                  </v-icon>
                  New Financing Round
                </div>
              </v-col>
              <v-col>
                <common-trs-btn
                  class="white--text"
                  type="secondary"
                  @click="goToRunWaterfall(data.id,data.name)"
                >
                  Run Waterfall Analysis
                </common-trs-btn>
              </v-col>
            </v-row>
          </template>
        </card-view>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import moment from 'moment'
  import CardView from '@/components/common/captable/components/CardView'
  import { getFeaturePermission } from '@/services/utils'

  export default {
    name: 'ScenariosList',

    components: {
      CardView,
    },

    props: {
      value: {
        type: Array,
      },
      checkedList: {
        type: Array,
        default: () => [],
      },
    },

    data: () => ({
      investorsTableHeader: [
        { text: 'Investors', value: 'name', sortable: true },
        { text: 'Investor Capital', value: 'invested_capital', sortable: true },
        { text: 'Granted Shares', value: 'granted_shares', sortable: true },
        { text: 'Percent', value: 'ratio_fully_diluted', sortable: true },
      ],
      newScenarioRoute: { name: 'ScenarioFinancingRound' },
    }),

    computed: {
      currentRoles () {
        return this.$store.getters['auth/roles']
      },
      isEditAllowed () {
        return getFeaturePermission(this.CONSTS.CAPTABLE_FEATURES.EDIT_SCENARIO, this.currentRoles)
      },
      isDeleteAllowed () {
        return getFeaturePermission(this.CONSTS.CAPTABLE_FEATURES.DELETE_SCENARIO, this.currentRoles)
      },
    },

    methods: {
      goToFinancing (id) {
        this.$router.push({
          name: 'ScenarioFinancingRound',
          query: {
            id: id,
          },
        })
      },
      goToEditFinancingRound (id, roundName) {
        this.$router.push({
          name: 'EditScenarioFinancingRound',
          query: {
            id: id,
            round: roundName,
          },
        })
      },
      goToRunWaterfall (id, name) {
        this.$router.push({
          name: 'RunWaterfall',
          query: {
            id: id,
            name: name,
          },
        })
      },
      routeToEdit (item) {
        const route = { name: 'EditScenarios', query: { id: item.id } }
        this.$router.push(route)
      },
      selectScenario (item) {
        this.$emit('isCheckedList', item)
      },
      errorHandler (item) {
        if (!this.checkedList.includes(item)) {
          if (this.checkedList.length === 2) {
            this.$store.dispatch('app/handleError', {
              message: 'Sorry! Only two scenarios are allowed to be compared at a time',
            })
          }
        }
      },
      getDate (date) {
        return moment(date).format('MM/DD/YYYY')
      },
      calculateSum (roundData, key) {
        let total = 0
        if (roundData && roundData.investors && roundData.investors.length > 0) {
          return roundData.investors.reduce((accumulator, currentValue) => {
            (total += +currentValue[key])
            return total
          }, 0)
        } else {
          return total
        }
      },
      getPercentValue (value) {
        if (!isNaN(value)) {
          return (value * 100).toFixed(2) + '%'
        } else {
          return 'NA'
        }
      },
      deleteFinancingRound (data, index) {
        this.$emit('deleteFinancingRound', data, index)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .summary-section  .col  span {
    display: inline-block;
    width: 47%;
  }
  .summary-section  .col  span:last-child {
    vertical-align: top;
  }
  ::v-deep .scenario-card {
    .card-item {
      min-height: 400px;
    }
  }
  .footer-bg {
    background: var(--v-accent-base);
  }
</style>
