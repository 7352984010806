<template>
  <common-trs-page-container>
    <template v-slot:page-content>
      <common-app-page-header-v2
        title="Scenarios & Waterfall Analysis"
        icon="mdi-graph"
        :padding="0"
      />
      <common-page-loader
        v-if="loading"
      />
      <v-row
        :justify="'end'"
        class="mt-8"
      >
        <common-trs-btn
          v-if="scenariosData.length>0"
          v-feature="CONSTS.CAPTABLE_FEATURES.CREATE_SCENARIO"
          type="primary"
          large
          class="white--text  mr-3"
          text
          :to="newScenarioRoute"
        >
          <v-icon class="mr-2">
            mdi-plus-circle
          </v-icon>
          New Scenario
        </common-trs-btn>
        <common-trs-btn
          v-if="scenariosData.length>0"
          type="primary"
          large
          class="white--text mr-3"
          text
          :disabled="checkedList.length !== 2"
          @click="compareScenarios"
        >
          Compare Scenario
        </common-trs-btn>
      </v-row>
      <scenarios-list
        v-model="scenariosData"
        :checked-list="checkedList"
        @delete="deleteScenario"
        @isCheckedList="checkedListHandler"
        @deleteFinancingRound="deleteFinancingRound"
      />
      <no-data-msg
        v-if="scenariosData.length===0 && !loading"
        :route-detail="isCreateAllowed ? newScenarioRoute : null"
      >
        <template v-slot:msg>
          No scenario found.
        </template>
        <template v-slot:action-btn-label>
          Create a Scenario
        </template>
        <template v-slot:tooltip-msg>
          Create New Scenario
        </template>
      </no-data-msg>
    </template>
  </common-trs-page-container>
</template>
<script>
  import NoDataMsg from '@/components/common/captable/components/NoDataMsg'
  import ScenariosList from './components/List'

  import * as captableService from '@/components/common/captable/services/captable'
  import { getFeaturePermission } from '@/services/utils'

  export default {
    name: 'ScenariosIndex',

    components: {
      NoDataMsg,
      ScenariosList,
    },

    data: () => ({
      scenariosData: [],
      newScenarioRoute: { name: 'AddScenarios' },
      loading: false,
      checkedList: [],
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      currentRoles () {
        return this.$store.getters['auth/roles']
      },
      isCreateAllowed () {
        return getFeaturePermission(this.CONSTS.CAPTABLE_FEATURES.CREATE_SCENARIO, this.currentRoles)
      },
    },

    mounted () {
      this.loadScenarios()
    },

    methods: {
      async loadScenarios () {
        try {
          this.loading = true
          const resp = await captableService.getScenariosList(this.captableId)
          if (resp && resp.data) {
            this.scenariosData = resp.data.scenarios.map((element) => {
              return { is_checked: false, ...element }
            })
          }
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      },
      async deleteScenario (item) {
        try {
          this.loading = true
          await captableService.deleteScenarioById(this.captableId, item.id)
          this.loadScenarios()
          this.$store.dispatch('app/message', { text: 'Scenario deleted successfully.' })
        } catch (e) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            e,
            message: 'Sorry! Unable to delete scenario. Please try again later.',
          })
        }
      },
      checkedListHandler (event) {
        if (event.is_checked) {
          if (this.checkedList.length > 2) {
            this.scenariosData = this.scenariosData.map(scenario => {
              if (scenario.id === event.id) {
                return { ...scenario, is_checked: false }
              } else return scenario
            })
          } else this.checkedList.push(event)
        } else {
          this.checkedList.map((item, index) => {
            if (item.id === event.id) {
              this.checkedList.splice(index, 1)
            }
          })
        }
      },
      compareScenarios () {
        this.$router.push({
          name: 'CompareScenarios',
          params: { selectedIds: this.checkedList },
        })
      },
      async deleteFinancingRound (item, index) {
        this.loading = true
        try {
          const data = this.getUpdatedData(item, index)
          await captableService.updateScenarioById(this.captableId, item.id, data)
          this.loadScenarios()
          this.$store.dispatch('app/message', { text: 'Scenario Financing Round deleted successfully.' })
        } catch (e) {
          this.$store.dispatch('app/handleError', {
            e,
            message: 'Sorry! Unable to delete scenario financing round. Please try again later.',
          })
        }
        this.loading = false
      },
      getUpdatedData (item, index) {
        const dataIdx = this.scenariosData.findIndex((d) => d.id === item.id)
        const data = JSON.parse(JSON.stringify(this.scenariosData[dataIdx]))
        data.scenario_rounds.splice(index, 1)
        data.scenario_rounds.forEach((d) => {
          this.clearNullData(d)
        })
        return data
      },
      clearNullData (data) {
        if (Object.keys(data) && Object.keys(data).length > 0) {
          Object.keys(data).forEach((item) => {
            if (data[item] === null || data[item] === undefined) {
              delete data[item]
            } else if (typeof (data[item]) === 'object') {
              this.clearNullData(data[item])
            }
          })
        }
      },
    },
  }
</script>
